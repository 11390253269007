import React, { useState, useEffect } from "react";
import "../styles/allorderdetails.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { BASE_URL } from '../api/config';
import { useNavigate } from "react-router-dom";

const AllOrderDetails = () => {
  const navigate = useNavigate(); 
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderStatus, setOrderStatus] = useState("All");

  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [itemsPerPage, setItemsPerPage] = useState(10); // Number of items per page


  // Fetch the order status from sessionStorage on load
  useEffect(() => {
    const storedOrderStatus = sessionStorage.getItem("BookStatus") || "All";
    setOrderStatus(storedOrderStatus); // Set the saved order status or default to "All"
  }, []);
  
  // Function to handle status changes and store them in sessionStorage
  const handleOrderStatusChange = (event) => {
    const selectedCategory = event.target.value;
    setOrderStatus(selectedCategory);
    sessionStorage.setItem("BookStatus", selectedCategory); // Save the selected category to session storage
  };

  // Fetch all orders from the backend
  useEffect(() => {
    const fetchOrders = async () => {
      const accessToken = sessionStorage.getItem('accessToken');
      try {
        const response = await fetch(`${BASE_URL}/api/getAllOrders`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setOrders(data);
        setFilteredOrders(data); // Initially set filtered orders as the full order list
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchOrders();
  }, []);

  // Filter orders based on the selected category
  useEffect(() => {
    let filteredData = orders;
    if (orderStatus !== "All") {
      switch(orderStatus) {
        case "Pending":
          filteredData = orders.filter((order) => order.verify === "0");
          break;
        case "Accepted":
          filteredData = orders.filter((order) => order.verify === "200");
          break;
        case "Finished":
          filteredData = orders.filter((order) => order.verify === "300");
          break;
        case "Canceled":
          filteredData = orders.filter((order) => order.verify === "404");
          break;
        default:
          filteredData = orders;
      }
    }
    setFilteredOrders(filteredData);
    setCurrentPage(1);
  }, [orderStatus, orders]);


  // Format date utility function
  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return date.toLocaleDateString([], { year: 'numeric', month: 'long', day: '2-digit' });
  };

  // Handle view booking details
  const handleView = (bookorder) => {
    sessionStorage.setItem("BookStatus", orderStatus); // Save the current category before navigating
    navigate('/layout/view_booking_details', { state: { bookorder } });
  };

  // Pagination logic
  const indexOfLastDealer = currentPage * itemsPerPage;
  const indexOfFirstDealer = indexOfLastDealer - itemsPerPage;
  const currentfilteredOrders = filteredOrders.slice(indexOfFirstDealer, indexOfLastDealer);
  const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  
  if (loading) return <center><div className="waitingspinner"></div></center>;
  if (error) return <p>Error loading orders: {error.message}</p>;

  return (
    <div className="sell__car">
      <div className="sell__car-wrapper">
        <div className="offer__wrapper">
          <div className="offer__top">
            <h2 className="sell__car-title">All Booking History</h2>

            <div className="filter__widget-01">
              <select value={orderStatus} onChange={handleOrderStatusChange}>
                <option value="All">All Orders</option>
                <option value="Pending">Pending Orders</option>
                <option value="Accepted">Accepted Orders</option>
                <option value="Canceled">Canceled Orders</option>
                <option value="Finished">Finished Orders</option>
              </select>
            </div>
          </div>

          <div className="offer__list">
            {currentfilteredOrders.map((order) => (
              <div className="offer__item" key={order.bookid}>
                <img
                  src={`${BASE_URL}/${order.car_photo}`}
                  alt={`${order.car_name} pic`}
                  className="all_order__image"
                />

                <div className="box__01">
                  <h3 className="client__name">{order.car_name}</h3>
                  <h6 className="avg__price">
                    ₹ <span>{order.price_per_km} /km</span>
                  </h6>
                  <h6 className="avg__price">
                    ₹ <span>{order.one_day_rent} /day</span>
                  </h6>
                  <span className="arrow__key">
                    <i className="ri-arrow-right-line"></i>
                  </span>
                </div>

                <div className="circle__wrapper">
                  <div className="box__02">
                    <CircularProgressbar
                    maxValue={15}
                      value={order.no_days}
                      text={`${order.no_days} day`}
                      styles={buildStyles({
                        pathColor: "#01d293",
                        textColor: "#fff",
                        trailColor: "#0b0c28",
                        textSize: "18px",
                      })}
                    />
                  </div>
                  <h4>Booking Days</h4>
                </div>

                <div className="box__03">
                  <span className="model__spend-icon">
                    <i className="ri-car-line"></i>
                  </span>
                  <h6 className="spend__place"> ➤ {order.start_place}</h6>
                  <h6 className="spend__place">⬅ {order.end_place}</h6>
                  <p className="spend__title">Booking place</p>
                </div>

                <div className="box__04">
                  <span className="model__spend-icon">
                    <i className="ri-share-forward-line"></i>
                  </span>
                  <h6 className="spend__place">{formatDateTime(order.start_date)}</h6>
                  <h6 className="spend__place">{formatDateTime(order.end_date)}</h6>
                  <p className="spend__title">Booking Date</p>
                </div>

                <div className="box__05">
                  <span className="model__spend-icon" style={{ color: getIconColor(order.verify) }}>
                    {order.verify === "0" ? (
                      <i className="ri-time-line"></i>
                    ) : order.verify === "200" ? (
                      <i className="ri-check-line"></i>
                    ) : order.verify === "300" ? (
                      <i className="ri-trophy-line"></i>
                    ) : order.verify === "404" ? (
                      <i className="ri-close-circle-line"></i>
                    ) : (
                      <i className="ri-error-warning-line"></i>
                    )}
                  </span>
                  <h6 className="spend__place" style={{ color: getIconColor(order.verify) }}>
                    {order.verify === "0" ? 'Pending' : 
                    order.verify === "200" ? 'Accepted' : 
                    order.verify === "300" ? 'Finished' : 
                    order.verify === "404" ? 'Canceled' : 'Unknown'}
                  </h6>
                  <p className="spend__title">Booking Status</p>
                  <span className="arrow__key2" onClick={() => handleView(order)}>
                    View &nbsp;<i className="ri-arrow-right-line"></i>
                  </span>
                </div>

              </div>
            ))}
          </div>


           {/* Pagination Controls */}
           {filteredOrders.length >10 && (
           <div className="pagination">
            <button 
              onClick={() => handlePageChange(currentPage - 1)} 
              disabled={currentPage === 1}
              className="pagination__button"
            >
              &laquo; Previous
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`pagination__button ${currentPage === index + 1 ? 'active' : ''}`}
              >
                {index + 1}
              </button>
            ))}
            <button 
              onClick={() => handlePageChange(currentPage + 1)} 
              disabled={currentPage === totalPages}
              className="pagination__button"
            >
              Next &raquo;
            </button>
          </div>
)}


        </div>
      </div>
    </div>
  );
};

// Function to get the appropriate color for booking status icons
const getIconColor = (verifyStatus) => {
  switch (verifyStatus) {
    case "0":
      return "orange"; // Pending
    case "200":
      return "green"; // Accepted
    case "300":
      return "yellow"; // Finished
    case "404":
      return "red"; // Canceled
    default:
      return "gray"; // Unknown
  }
};

export default AllOrderDetails;
