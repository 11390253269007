import React, {useEffect, useState } from 'react';
import axios from 'axios'; // Ensure axios is imported
import Swal from 'sweetalert2'; // Ensure SweetAlert2 is imported
import { BASE_URL } from '../../api/config';
import './login.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';


const Login = () => {
    const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [resendVisible, setResendVisible] = useState(false);
  const [timer, setTimer] = useState(60);


  useEffect(() => {
    let interval;
    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setResendVisible(true);
      
    }
    
    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [otpSent, timer]);


  const validateMobileNumber = (number) => {
    // Regular expression to match a 10-digit number
    const regex = /^[0-9]{10}$/;
    return regex.test(number);
  };




  const handleRequestOTP = async () => {
    if (validateMobileNumber(mobileNumber)) {
      try {
        // Send a POST request to request an OTP
        const response = await axios.post(`${BASE_URL}/api/ADMINLOGINweb`, {
          action: 'Login',
          number: mobileNumber,
        });

        // Handle successful OTP request
        if (response.status === 200) {
          Swal.fire({
            title: 'OTP Sent!',
            text: 'An OTP has been sent to your mobile number',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,  // Hides the OK button
            customClass: {
              popup: 'custom-popup',    // Class for the alert popup
              title: 'custom-title',    // Class for the title
              content: 'custom-content' // Class for the content text
            }
          }).then(() => {
            setOtpSent(true);
            setTimer(60); // Reset timer when OTP is sent
           setResendVisible(false);
          });
          
        }
        else{
          Swal.fire({
            title: 'Error!',
            text: response.body,
            icon: 'error',
            timer: 2000,
              showConfirmButton: false,  // Hides the OK button
              customClass: {
                popup: 'custom-popup',    // Class for the alert popup
                title: 'custom-title',    // Class for the title
                content: 'custom-content' // Class for the content text
              }
          });
        }
      } catch (error) {
        Swal.fire({
          title: 'Error!',
          text: 'Invalid mobile number for sent OTP',
          icon: 'error',
          timer: 2000,
            showConfirmButton: false,  // Hides the OK button
            customClass: {
              popup: 'custom-popup',    // Class for the alert popup
              title: 'custom-title',    // Class for the title
              content: 'custom-content' // Class for the content text
            }
        });
      }
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Please enter a valid 10-digit mobile number',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,  // Hides the OK button
        customClass: {
          popup: 'custom-popup',    // Class for the alert popup
          title: 'custom-title',    // Class for the title
          content: 'custom-content' // Class for the content text
        }
      });
    }
  };


  const handleVerifyOTP = async () => {
    try {
      // Send a POST request to verify the OTP
      const response = await axios.post(`${BASE_URL}/api/verifyOtp`, {
        number: mobileNumber,
        otp,
      });

      // Handle successful OTP verification
      if (response.status === 200) {
        const { accessToken } = response.data;
        localStorage.setItem('Number', mobileNumber);
        sessionStorage.setItem('accessToken', accessToken);

        Swal.fire({
          title: 'Success!',
          text: response.data.message,
          icon: 'success',
          timer: 2000,
            showConfirmButton: false,  // Hides the OK button
            customClass: {
              popup: 'custom-popup',    // Class for the alert popup
              title: 'custom-title',    // Class for the title
              content: 'custom-content' // Class for the content text
            }
        }).then(() => {
            navigate('/layout/dashboard'); 
        });

        fetchUserDtl(mobileNumber,otp);
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Invalid OTP',
        icon: 'error',
        timer: 2000,
            showConfirmButton: false,  // Hides the OK button
            customClass: {
              popup: 'custom-popup',    // Class for the alert popup
              title: 'custom-title',    // Class for the title
              content: 'custom-content' // Class for the content text
            }
      });
    }
  };

  const fetchUserDtl = async (num, otp) => {
    const accessToken = sessionStorage.getItem("accessToken");
    try {
      const url = `${BASE_URL}/api/fetchUserByNumberAndOTP`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          number: num.toString(),
          otp: otp.toString(),
        }),
      });

      if (!response.ok) {
        // Optionally log the error here without alerting
        console.error("Failed to fetch user details:", response.statusText);
        return; // Do nothing if there's an error
      }
      const data = await response.json();
      sessionStorage.setItem('userDetails', JSON.stringify(data));
      
    } catch (error) {
      console.error("Error fetching user details:", error);
      // Handle fetch error quietly (you can log or set a state if needed)
    } finally {
      
    }
  };



  



  return (
    <div className="Loginpage">
      <div className="logincontainer">
        <div className='imgseataxi'></div>
        <h2 className="title">Login</h2>

        <div className="formGroup">
          <label htmlFor="mobile" className="loglabel">Mobile Number</label>
          <input
            type="text"
            id="mobile"
            maxLength="10"
            placeholder="Enter your mobile number"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            className="loginput"
            autoFocus
          />
        </div>

        {otpSent && (
          <div className="formGroup">
            <label htmlFor="otp" className="loglabel">OTP</label>
            <input
              type="text"
              id="otp"
              maxLength="6"
              placeholder="Enter the OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className="loginput"
             
            />
          </div>
        )}

      {/* Countdown Timer or Resend OTP Button */}
        {otpSent && (
          !resendVisible ? (
            <h5 className='resendbtntimr'>Resend OTP in {timer} seconds</h5>
          ) : (
            <h5 className='resendbtn' onClick={handleRequestOTP}>Resend OTP</h5>
          )
        )}

        {!otpSent ? (
          <button className="logbutton" onClick={handleRequestOTP}>
            Send OTP
          </button>
        ) : (
          <button className="logbutton" onClick={handleVerifyOTP}>
            Verify OTP
          </button>
        )}
      </div>
    </div>
  );
};

export default Login;