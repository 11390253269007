import React, { useState, useEffect } from "react";
import "../styles/profile.css";
import { BASE_URL } from '../api/config';
import Swal from 'sweetalert2'; 
import ConfirmationModal from "../components/Model/ConfirmationModal"; // Import your modal component


const Profile = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  // State to handle active tab (My Details or Contact Us)
  const [activeTab, setActiveTab] = useState("myDetails");
  const [selectedContactId, setSelectedContactId] = useState(null);

  // State to store user details from sessionStorage
  const [userDetails, setUserDetails] = useState(null);

  // State to store contact details fetched from the backend
  const [contactDetails, setContactDetails] = useState([]);

  // State to store new contact form inputs
  const [newContact, setNewContact] = useState({
    email: "",
    phn: "",
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState("");

  // Fetch user details from sessionStorage when the component mounts
  useEffect(() => {
    const storedUserDetails = sessionStorage.getItem("userDetails");
    if (storedUserDetails) {
      setUserDetails(JSON.parse(storedUserDetails));
    }
  }, []);

  // Handle tab switching between "My Details" and "Contact us"
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "contactUs") {
      fetchContactDetails();
    }
  };

  // Fetch contact details from backend API when "Contact us" is clicked
  const fetchContactDetails = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    try {
      const response = await fetch(`${BASE_URL}/api/contactus`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch contact details');
      }

      const data = await response.json();
      setContactDetails(data);
    } catch (error) {
      console.error('Error fetching contact details:', error);
    }
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewContact({ ...newContact, [name]: value });
  };

  // Email validation function
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  // Phone number validation function
  const validatePhoneNumber = (phn) => {
    const re = /^[0-9]{10,15}$/; // Adjust for the required length
    return re.test(String(phn));
  };

  // Handle form submission to add new contact
  const handleAddContact = async (e) => {
    e.preventDefault();

    // Validate email and phone number
    if (!validateEmail(newContact.email)) {
      alert("Please enter a valid email.");
      return;
    }

    if (!validatePhoneNumber(newContact.phn)) {
      alert("Please enter a valid phone number.");
      return;
    }

    const accessToken = sessionStorage.getItem("accessToken");

    try {
      const response = await fetch(`${BASE_URL}/api/contactus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          email : newContact.email,
          phone : newContact.phn,
      }),
      });

      if (response.status === 409) {
        const data = await response.json(); // Parse the JSON response
        Swal.fire({
          title: data.message, 
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
          customClass: {
            popup: 'custom-popup',
            title: 'custom-title',
            content: 'custom-content'
          }
        });
        setNewContact({
          email: "",
          phn: "",
        });
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to add contact');
      }

      Swal.fire({
        title: 'New Contact Added Successfully',
        // text: 'Invalid OTP',
        icon: 'success',
        timer: 2000,
            showConfirmButton: false,  // Hides the OK button
            customClass: {
              popup: 'custom-popup',    // Class for the alert popup
              title: 'custom-title',    // Class for the title
              content: 'custom-content' // Class for the content text
            }
      }).then(() => {
        fetchContactDetails();
        setNewContact({ email: "", phn: "" }); 
      });

    } catch (error) {
      console.error('Error adding contact:', error);
    }
  };

  const openModal = (dealerId) => {
    setSelectedContactId(dealerId);
    setModalOpen(true);
  };

  const handleConfirm = async () => {
    handleDeleteContact(selectedContactId);
    setModalOpen(false);
  };

  const handleDeleteContact = async (contactId) => {
    const accessToken = sessionStorage.getItem("accessToken");
  
    try {
      const response = await fetch(`${BASE_URL}/api/contactus/${contactId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete contact');
      }
  
      // Refresh the list after deletion
      fetchContactDetails();
    } catch (error) {
      console.error('Error deleting contact:', error);
    }
  };



  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

    if (file && allowedTypes.includes(file.type)) {
      if (file.size > 5 * 1024 * 1024) {
        setError("File size should be less than 5MB.");
      } else {
        setError("");
        setSelectedImage(file); // Store the selected image
      }
    } else {
      setError("Please select a valid image (jpg, jpeg, png).");
    }
  };


  const handleAddProfile = async (e) => {
    e.preventDefault();
  
    // Check if an image is selected
    if (!selectedImage) {
      setError("Please choose an image before submitting.");
      return;
    }
    const accessToken = sessionStorage.getItem("accessToken");
    // Prepare form data to send to the backend
    const formData = new FormData();
    formData.append("profile_pic", selectedImage); // Image data
    formData.append("num", userDetails.usr_num);  // User number
  
    try {
      // Make API call to the backend with the profile image and user number
      const response = await fetch(`${BASE_URL}/Add_Profile`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`, 
        },
      });
  
     
      if (response.ok) {
        await console.log(response.json(),'image');
        
        Swal.fire({
        title: 'Profile changed ',
        // text: 'Invalid OTP',
        icon: 'success',
        timer: 2000,
            showConfirmButton: false,  // Hides the OK button
            customClass: {
              popup: 'custom-popup',    // Class for the alert popup
              title: 'custom-title',    // Class for the title
              content: 'custom-content' // Class for the content text
            }
      }).then(async () => {
       await fetchUserDtl(userDetails.usr_num,userDetails.otp);
      });

      } else {
        const errorResult = await response.json();
        setError(errorResult.message || "Error updating profile.");
      }
    } catch (error) {
      setError("Something went wrong. Please try again.");
    }
  };
  
  const fetchUserDtl = async (num, otp) => {
    const accessToken = sessionStorage.getItem("accessToken");
    try {
      const url = `${BASE_URL}/api/fetchUserByNumberAndOTP`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          number: num.toString(),
          otp: otp.toString(),
        }),
      });

      if (!response.ok) {
        // Optionally log the error here without alerting
        console.error("Failed to fetch user details:", response.statusText);
        return; // Do nothing if there's an error
      }
      const data = await response.json();
      setUserDetails(data); 
      sessionStorage.setItem('userDetails', JSON.stringify(data));
      
    } catch (error) {
      console.error("Error fetching user details:", error);
      // Handle fetch error quietly (you can log or set a state if needed)
    } finally {
      
    }
  };

  return (
    <div className="settings">
      <div className="settings__wrapper">
        <h2 className="settings__title">Admin Profile</h2>

        <div className="settings__top">
          <button
            className={`setting__btn ${activeTab === "myDetails" ? "active__btn" : ""}`}
            onClick={() => handleTabClick("myDetails")}
          >
            My Details
          </button>
          <button
            className={`setting__btn ${activeTab === "contactUs" ? "active__btn" : ""}`}
            onClick={() => handleTabClick("contactUs")}
          >
            Contact us
          </button>
        </div>

        {activeTab === "myDetails" && userDetails && (
          <div className="details__form">
            <h2 className="profile__title">Profile</h2>
            <p className="profile__desc">
              Update your photo and personal details here
            </p>
            <form onSubmit={handleAddProfile}>
              <img
                src={`${BASE_URL}/${userDetails.usr_profile}`}
                alt={`${userDetails.usr_name} pic`}
                className="Admin__image"
              />

              <div className="form__group">
                <div>
                  <label>User Name</label>
                  <input
                    type="text"
                    value={userDetails.usr_name || ""}
                    placeholder="Admin Name"
                    readOnly
                  />
                </div>

                <div>
                  <label>Phone Number</label>
                  <input
                    type="number"
                    value={userDetails.usr_num || ""}
                    placeholder="+880 17*******"
                    readOnly
                  />
                </div>
              </div>

              <div className="form__group">
                <div>
                  <label>Your Photo</label>
                  <p className="profile-img__desc">
                    This will be displayed in your profile
                  </p>
                  <input type="file" accept="image/*" onChange={handleImageChange} />
          {error && <p className="error-message">{error}</p>} {/* Display error message */}
                 
                </div>
               
              </div>
              <button type="submit" className="add__contact-btn">
                Change Profile
              </button>
            </form>
          </div>
        )}

        {activeTab === "contactUs" && (
          <div className="contact__form">
            <h2 className="profile__title">Contact Details</h2>
            <p className="profile__desc">Update your Seataxi ContactUs Details</p>

            {/* Form to add new contact */}
            <form onSubmit={handleAddContact}>
              <div className="form__group">
                <div>
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    value={newContact.email}
                    onChange={handleInputChange}
                    placeholder="Enter email"
                    required
                  />
                </div>
                <div>
                  <label>Phone Number</label>
                  <input
                    type="text"
                    name="phn"
                    value={newContact.phn}
                    onChange={handleInputChange}
                    placeholder="Enter phone number"
                    required
                  />
                </div>
              </div>
              <button type="submit" className="add__contact-btn">
                Add Contact
              </button>
            </form>

            {/* List of existing contacts */}
            {contactDetails.length > 0 ? (
              <div className="contactus-list">
                {contactDetails.map((contact, index) => (
                  <div className="contactus" key={index}>
                     
                    <i className="ri-delete-bin-fill" onClick={() => 
                      openModal(contact.id)}></i>
                      {/* onClick={() => handleDeleteContact(contact.id)} */}
                    <p>{contact.email}</p>
                    <p>{contact.phn}</p>
                   
                  </div>
                 
                ))}
              </div>
            ) : (
              <p>No contact details available.</p>
            )}
          </div>
        )}
      </div>
      <ConfirmationModal 
        isOpen={isModalOpen} 
        onClose={() => setModalOpen(false)} 
        onConfirm={handleConfirm} 
        message={`Are you sure you want to delete this contact ?`} 
      />
    </div>
  );
};




export default Profile;
