import React, { useEffect } from 'react';
import Header from './Header';
import "../assets/css/home.css";
import taxi_pic from "../assets/image/taxng2.png";
import cars_1 from "../assets/image/Audi-Q3_.jpg";
import cars_2 from "../assets/image/busg.jpg";
import cars_4 from "../assets/image/crtyu.jpg";
import crd_cars from "../assets/image/budcx.png";
import crd_cars_2 from "../assets/image/Toyota.png";
import crd_cars_3 from "../assets/image/travxpg.png";
import app_sor from "../assets/image/google-play-and-apple-app-store-logos-22-removebg-preview.png";
import app_sor_1 from "../assets/image/google-play-and-apple-app-store-logos-22_-_Copy-removebg-preview.png";
import app_pic_3 from "../assets/image/double app.png";
import mbl_scr from "../assets/image/taxng3.png";
import Footer from './Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000,
          offset: 100,
          once: true,
        });
      }, []);


  return (
   <>
   <Header/>
   <div className='sea_taxi_main_div'>
      <div className='sea_taxi_amin_div_1'>
        
        <div className='sea_taxi_sub_conts' data-aos="fade-right">
          <h1>Find, book, and<br />rental car in <span>Easy</span><br />steps. </h1>
          <p>Get a car wherever and whenever you need it with your<br />iOS or Android devices</p>
          <div className='home_txi_hed_btn'>
            <div className='home_taxi_app_download'>
              <div className='home_taxi_app_download_1'>
                <img src={app_sor} alt="" />
              </div>
              <div className='home_taxi_app_download_1'>
                <img src={app_sor_1} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className='sea_taxi_div_pic' data-aos="fade-left">
          <img src={taxi_pic} alt=""/>
        </div>
      </div>
    </div>







    <div className='sea_taxi_add_car'>
      <div className='sea_taxi_add_car_div'>
        <h2 className='title_nearloc'>Near By Your Location</h2>
        {/* <div className='sea_taxi_car_view_btn'>
            <button>View All</button>
        </div> */}
      </div>
      <div className='sea_taxi_add_car_main_div'>
        <div className='sea_taxi_card_view_div' data-aos="fade-up">
          <img src={cars_1} alt=""/>
        </div>
        <div className='sea_taxi_card_view_div' data-aos="fade-up">
          <img src={cars_2} alt=""/>
        </div>
        <div className='sea_taxi_card_view_div' data-aos="fade-up">
          <img src={cars_1} alt=""/>
        </div>
        <div className='sea_taxi_card_view_div' data-aos="fade-up">
          <img src={cars_4} alt=""/>
        </div>
        <div className='sea_taxi_card_view_div' data-aos="fade-up">
          <img src={cars_1} alt=""/>
        </div>
      </div>
    </div>









   <section id="how-it-works">
   <div className='book_steps_div'>
        <h4 data-aos="fade-up">HOW IT WORK</h4>
        <h3 data-aos="fade-up">Rent with following 3 working steps</h3>


        <div className='card_row_div'>

            <div className='single_card' data-aos="fade-right">
                <div className='card_icon_div'>
                    <i className="ri-map-pin-line"></i>
                </div>
                <h4>Choose location</h4>
                <h5>Choose your location and find</h5>
                <h5>your best car</h5>
            </div>

            <div className="dotted-line" data-aos="fade-in"></div>

            <div className='single_card' data-aos="fade-up">
                <div className='card_icon_div'>
                <i className="ri-calendar-line"></i>
                </div>
                <h4>Pick-up date</h4>
                <h5>Select your pick up date and</h5>
                <h5>time to book your car</h5>
            </div>

            <div className="dotted-line" data-aos="fade-in"></div>

            <div className='single_card' data-aos="fade-left">
                <div className='card_icon_div'>
                    <i className="ri-car-line"></i>
                </div>
                <h4>Book your car</h4>
                <h5>Book your car we will accecpt</h5>
                <h5>your booking quickly</h5>
            </div>

        </div>
   </div>
   </section>


   {/* <div className='sea_taxi_sub_div'>
    <div className='sea_taxi_sub_div_1'>

    <div className='sea_mbl_pic_add'>
            <img src={mbl_scr} alt="" />
        </div>
        <div className='sea_taxi_sub_add_logo'>
            <img src={sub_logo_1} alt=""/>
            <div className='sea_taxi_sub_logo_conts'>
                <p>Welcome to Sea Taxi, your dependable travel solution for fast ,safe, and conveninet transportation across the road. Whether you're commuting for business, exploring coastal destination, or embarking on a leisurely day trip reliable sea taxis ensure a smooth and comfortable ride. With modern vehicles operated by experienced professional, we prioritizeyour safety and satisfaction. </p>
            </div>
         </div>
       
       
   </div>
   </div> */}
<section id="why-choose-us" data-aos="fade-up">
   <div className='sea_taxi_chooseus'>
     <div className='sea_taxi_chooseus2'>
       <div className='sea_taxi_chooseus_picbg' data-aos="fade-right">
         <img src={mbl_scr} alt="" />
       </div>

       <div className='sea_taxi_chooseus_cont' data-aos="fade-left">
         <h4 className='choose_heading' data-aos="zoom-in">WHY CHOOSE US</h4>
         <h3>We offer the best experience<br />with our rental deals</h3>

         <div className='listtile-container'>
           <div className='listtile' data-aos="fade-up" data-aos-delay="100">
             <i className="ri-money-dollar-circle-line"></i>
             <div>
               <h4>Best price guaranteed</h4>
               <p>Find a lower price? We'll refund you 100% of the difference</p>
             </div>
           </div>

           <div className='listtile' data-aos="fade-up" data-aos-delay="200">
             <i className="ri-steering-2-line"></i>
             <div>
               <h4>Experience driver</h4>
               <p>Don't have a driver? Don't worry, we have many experienced drivers for you.</p>
             </div>
           </div>

           <div className='listtile' data-aos="fade-up" data-aos-delay="300">
             <i className="ri-calendar-check-line"></i>
             <div>
               <h4>24-hour car booking</h4>
               <p>Book your car anytime and we will accept your bookings.</p>
             </div>
           </div>

           <div className='listtile' data-aos="fade-up" data-aos-delay="400">
             <i className="ri-customer-service-2-line"></i>
             <div>
               <h4>24/7 technical support</h4>
               <p>Have a question? Contact Carentall support anytime when you have a problem.</p>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
</section>


   

   <div className='sea_taxi_car_details_view'>
    <div className='sea_taxi_car_details_view_1'>
        <h2 className='taxi_car_view_head' data-aos="fade-in">Our GlideFleets</h2>
    </div>
    <div className='sea_taxi_details_view'>
        <div className='sea_taxi_details_view_div'>
            <div className='sea_taxi_details_view_div_1' data-aos="fade-right">
                <div className='sea_taxi_crd_pic'>
                    <img src={crd_cars} alt="" />
                </div>
                    <h2>Comfortable bus</h2>
                    <p className='card_taxi_text_p'>Expeince the ultimate comfort with confortable cabs,where every ride is tailored for your convenience and relaxtion</p>
                    <p class="flex-container"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM13.5003 8C13.8278 8.43606 14.0625 8.94584 14.175 9.5H16V11H14.175C13.8275 12.7117 12.3142 14 10.5 14H10.3107L14.0303 17.7197L12.9697 18.7803L8 13.8107V12.5H10.5C11.4797 12.5 12.3131 11.8739 12.622 11H8V9.5H12.622C12.3131 8.62611 11.4797 8 10.5 8H8V6.5H16V8H13.5003Z"></path></svg><span>30/KM</span></p>
                    <p>TN 75 J xxxx</p>
            </div>  
            <div className='sea_taxi_details_view_div_1' data-aos="fade-up">
            <div className='sea_taxi_crd_pic'>
                    <img src={crd_cars_2} alt="" />
                </div>
                    <h2>Comfortable car</h2>
                    <p className='card_taxi_text_p'>Expeince the ultimate comfort with confortable cabs,where every ride is tailored for your convenience and relaxtion</p>
                    <p class="flex-container"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM13.5003 8C13.8278 8.43606 14.0625 8.94584 14.175 9.5H16V11H14.175C13.8275 12.7117 12.3142 14 10.5 14H10.3107L14.0303 17.7197L12.9697 18.7803L8 13.8107V12.5H10.5C11.4797 12.5 12.3131 11.8739 12.622 11H8V9.5H12.622C12.3131 8.62611 11.4797 8 10.5 8H8V6.5H16V8H13.5003Z"></path></svg><span>60/KM</span></p>
                    <p>TN 75 D xxxx</p>
            </div>  
            <div className='sea_taxi_details_view_div_1' data-aos="fade-left">
            <div className='sea_taxi_crd_pic'>
                    <img src={crd_cars_3} alt="" />
                </div>
                    <h2>Comfortable Van</h2>
                    <p className='card_taxi_text_p'>Expeince the ultimate comfort with confortable cabs,where every ride is tailored for your convenience and relaxtion</p>
                    <p class="flex-container"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM13.5003 8C13.8278 8.43606 14.0625 8.94584 14.175 9.5H16V11H14.175C13.8275 12.7117 12.3142 14 10.5 14H10.3107L14.0303 17.7197L12.9697 18.7803L8 13.8107V12.5H10.5C11.4797 12.5 12.3131 11.8739 12.622 11H8V9.5H12.622C12.3131 8.62611 11.4797 8 10.5 8H8V6.5H16V8H13.5003Z"></path></svg><span>90/KM</span></p>
                    <p>TN 75 W xxxx</p>
            </div>  
        </div>
    </div>
   </div>



   <div className='sea_taxi_sub_seting_div'>
        <div className='sea_taxi_sub_seting_div_1' data-aos="fade-up">
            <h2>WE DO MORE THEN YOU WISH</h2>
        </div>
        <div className='sea_taxi_sub_seting_div_conts'>
                <div className='sea_taxi_sub_seting_div_conts_1'  data-aos="fade-right">
                    <div className='sea_txic_icon_pic'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26ZM12.0006 15.968L16.2473 18.3451L15.2988 13.5717L18.8719 10.2674L14.039 9.69434L12.0006 5.27502L9.96214 9.69434L5.12921 10.2674L8.70231 13.5717L7.75383 18.3451L12.0006 15.968Z"></path></svg></div>
                    <div className='sea_txic_icon_conts'>
                    <h2>HOME PICK</h2>
                    <p>With Home pick-up,enjoy the convenience of door-to-door service We'll pick you up right from your doorstep ensuring a hassle-free start to your journey</p>
                </div>
                </div>
                <div className='sea_taxi_sub_seting_div_conts_1' data-aos="fade-left">
                <div className='sea_txic_icon_pic'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM13 12H17V14H11V7H13V12Z"></path></svg></div>
                <div className='sea_txic_icon_conts'>
                    <h2>FAST BOOKING</h2>
                    <p>With fast booking, getting a ride has never been easir! our quick and user-friendly booking system lets you reserve a vehicles in just a few click </p>
                    </div>
                </div>
        </div>
        <div className='sea_taxi_sub_seting_div_conts'>
        <div className='sea_taxi_sub_seting_div_conts_1' data-aos="fade-right">
                    <div className='sea_txic_icon_pic'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M7.10508 15.2101C8.21506 15.6501 9 16.7334 9 18C9 19.6569 7.65685 21 6 21C4.34315 21 3 19.6569 3 18C3 16.6938 3.83481 15.5825 5 15.1707V8.82929C3.83481 8.41746 3 7.30622 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6C9 7.30622 8.16519 8.41746 7 8.82929V11.9996C7.83566 11.3719 8.87439 11 10 11H14C15.3835 11 16.5482 10.0635 16.8949 8.78991C15.7849 8.34988 15 7.26661 15 6C15 4.34315 16.3431 3 18 3C19.6569 3 21 4.34315 21 6C21 7.3332 20.1303 8.46329 18.9274 8.85392C18.5222 11.2085 16.4703 13 14 13H10C8.61653 13 7.45179 13.9365 7.10508 15.2101Z"></path></svg></div>
                    <div className='sea_txic_icon_conts'>
                    <h2>FAST BOOKING</h2>
                    <p>With fast booking, getting a ride has never been easir! our quick and user-friendly booking system lets you reserve a vehicles in just a few click </p>
                </div>
                </div>
                <div className='sea_taxi_sub_seting_div_conts_1' data-aos="fade-left">
                <div className='sea_txic_icon_pic'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M8.68637 4.00008L11.293 1.39348C11.6835 1.00295 12.3167 1.00295 12.7072 1.39348L15.3138 4.00008H19.0001C19.5524 4.00008 20.0001 4.4478 20.0001 5.00008V8.68637L22.6067 11.293C22.9972 11.6835 22.9972 12.3167 22.6067 12.7072L20.0001 15.3138V19.0001C20.0001 19.5524 19.5524 20.0001 19.0001 20.0001H15.3138L12.7072 22.6067C12.3167 22.9972 11.6835 22.9972 11.293 22.6067L8.68637 20.0001H5.00008C4.4478 20.0001 4.00008 19.5524 4.00008 19.0001V15.3138L1.39348 12.7072C1.00295 12.3167 1.00295 11.6835 1.39348 11.293L4.00008 8.68637V5.00008C4.00008 4.4478 4.4478 4.00008 5.00008 4.00008H8.68637ZM6.00008 6.00008V9.5148L3.5148 12.0001L6.00008 14.4854V18.0001H9.5148L12.0001 20.4854L14.4854 18.0001H18.0001V14.4854L20.4854 12.0001L18.0001 9.5148V6.00008H14.4854L12.0001 3.5148L9.5148 6.00008H6.00008ZM12.0001 16.0001C9.79094 16.0001 8.00008 14.2092 8.00008 12.0001C8.00008 9.79094 9.79094 8.00008 12.0001 8.00008C14.2092 8.00008 16.0001 9.79094 16.0001 12.0001C16.0001 14.2092 14.2092 16.0001 12.0001 16.0001ZM12.0001 14.0001C13.1047 14.0001 14.0001 13.1047 14.0001 12.0001C14.0001 10.8955 13.1047 10.0001 12.0001 10.0001C10.8955 10.0001 10.0001 10.8955 10.0001 12.0001C10.0001 13.1047 10.8955 14.0001 12.0001 14.0001Z"></path></svg></div>
                <div className='sea_txic_icon_conts'>
                    <h2>GPS SEARCHING</h2>
                    <p>With our GPS Tracking,you're always in the Known! Track your ride in real time pickup to drop-off,ensuring a smooth and transparent  journey </p>
                    </div>
                </div>
        </div>
   </div> 

<section id="download-app">
  <div className='sea_taxi_mobile_app'>
   <h2>BEST CAB SERVICE</h2>
    <div className='sea_taxi_mobile_app_1'>
        <div className='sea_taxi_mobile_app_conts'>
            <h1>Download Sea Taxi App Now!</h1>
            <p>Welcome to the Sea Taxi App,your all-in-one soluction for quickand easy transportation Whether you're commuting or exploring. Our App lets you book a ride in secound ,track your taxi in real-time and enjou a smooth,scenic journey across the road Download now and experience hassle-free travel at your fingertips! </p>
            <p>Want to take the scenic route? Our drivers are knowledgeable about the best spots and routes to give you a more scenic and enjoyable journey.Plan ahead by scheduling your ride in advance, ensuring you're always on time for important appointments or early-morning flights. Got questions or need assistance? Our in-app customer support is available around the clock to assist with any issues or concerns.</p>
            <div className='sea_taxi_app_download'>
                <div className='sea_taxi_app_download_1'>
                    <img src={app_sor} alt="" />
                </div>
                <div className='sea_taxi_app_download_1'>
                    <img src={app_sor_1} alt="" />
                </div>
            </div>
        </div>
        <div className='app_pic_add'>
        <img src={app_pic_3} alt=""/>
        </div>
    </div>
   
   </div>
 </section>

   <Footer/>
   </>
  )
}

export default Home