import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../api/config'; 
import "../styles/dealerdtls.css";
import { useNavigate } from 'react-router-dom';
import ImagePopup from '../components/reuseable/ImagePopup';
import DealerForm from '../components/Form/DealerForm';

const DealerDtls = () => {
  const navigate = useNavigate(); 
  const [Dealer, setDealer] = useState([]);
  const [dealerRequests, setDealerRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dealerStatus, setDealerStatus] = useState("active");
  const [searchTerm, setSearchTerm] = useState('');
  const [imagePopupOpen, setImagePopupOpen] = useState(false);
  const [popupImageSrc, setPopupImageSrc] = useState('');
  const [dealerFormOpen, setDealerFormOpen] = useState(false);
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    const storedDealerStatus = sessionStorage.getItem("delrStatus") || "active";
    setDealerStatus(storedDealerStatus); 
  }, []);

  const openImagePopup = (src) => {
    setPopupImageSrc(src);
    setImagePopupOpen(true);
  };

  const closeImagePopup = () => {
    setImagePopupOpen(false);
  };

  const handleDealerStatusChange = (event) => {
    const selectedCategory = event.target.value;
    setDealerStatus(selectedCategory);
    sessionStorage.setItem("delrStatus", selectedCategory);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const fetchDealerRequests = async () => {
      try {
        const accessToken = sessionStorage.getItem("accessToken");
        const response = await fetch(`${BASE_URL}/api/getAllDealers`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch dealer requests');
        }

        const data = await response.json();
        setDealer(data);
        setDealerRequests(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDealerRequests();
  }, []);

  useEffect(() => {
    let filteredData = Dealer;

    if (dealerStatus === "active") {
      filteredData = filteredData.filter((dlr) => dlr.Ad_Verify === 1);
    } else if (dealerStatus === "deactive") {
      filteredData = filteredData.filter((dlr) => dlr.Ad_Verify === 2);
    } else if (dealerStatus === "pending") {
      filteredData = filteredData.filter((dlr) => dlr.Ad_Verify === 0);
    }

    if (searchTerm) {
      filteredData = filteredData.filter((dealer) => 
        dealer.usr_name.toLowerCase().includes(searchTerm.toLowerCase()) || 
        dealer.usr_num.includes(searchTerm)
      );
    }

    setDealerRequests(filteredData);
  }, [dealerStatus, Dealer, searchTerm]);

  const handleView = (dealer) => {
    sessionStorage.setItem("delrStatus", dealerStatus); // Save the current category before navigating
    navigate('/layout/view_dealer_details', { state: { dealer } });
  };

  // Pagination logic
  const indexOfLastDealer = currentPage * itemsPerPage;
  const indexOfFirstDealer = indexOfLastDealer - itemsPerPage;
  const currentDealers = dealerRequests.slice(indexOfFirstDealer, indexOfLastDealer);
  const totalPages = Math.ceil(dealerRequests.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (loading) return <center><div className="waitingspinner"></div></center>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="dashboard">
      <div className="dashboard__wrapper">
        <div className="dealerdtls-requests">
          <div className='topflex'>
            <h2 className="dealerdtls-requests__heading">Dealer Details</h2>
            <div className="search__box">
              <input
                type="text"
                placeholder="Search by name or phone number"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <span>
                <i className="ri-search-line"></i>
              </span>
            </div>

            <button className="add-dealer-button" onClick={() => setDealerFormOpen(true)}>
                <span className="button-text">Add Dealer</span>
                <span className="button-icon">
                    <i className="ri-add-line"></i>
                </span>
            </button>


            <div className="filter__widget-01">
              <select value={dealerStatus} onChange={handleDealerStatusChange}>
                <option value="active">Active</option>
                <option value="deactive">Deactive</option>
                <option value="pending">Pending</option>
              </select> 
            </div>   
          </div>
          <table className="dealerdtls-requests__table">
            <thead className="dealerdtls-requests__thead">
              <tr className="dealerdtls-requests__thead-row">
                <th className="dealerdtls-requests__thead-cell">ID</th>
                <th className="dealerdtls-requests__thead-cell">Profile</th>
                <th className="dealerdtls-requests__thead-cell">Dealer Name</th>
                <th className="dealerdtls-requests__thead-cell">Phone Number</th>
                <th className="dealerdtls-requests__thead-cell">Address</th>
                <th className="dealerdtls-requests__thead-cell">Pincode</th>
                <th className="dealerdtls-requests__thead-cell">Auth Status</th>
                <th className="dealerdtls-requests__thead-cell">Id proof</th>
                <th className="dealerdtls-requests__thead-cell">Actions</th>
              </tr>
            </thead>
            <tbody className="dealerdtls-requests__tbody">
              {currentDealers.map((dealer,index) => (
                <tr key={dealer.id} className="dealerdtls-requests__tbody-row">
                  <td className="dealerdtls-requests__tbody-cell">{indexOfFirstDealer+index+1}</td>
                  <td className="dealerdtls-requests__tbody-cell">
                  
                     <img
  src={dealer.usr_profile ? `${BASE_URL}/${dealer.usr_profile}` : require('../assets/images/profile-02.png')}
  alt={`${dealer.usr_name} Profile`}
   className="dealerdtls-requests__profile-image"
  onClick={() => dealer.usr_profile && openImagePopup(`${BASE_URL}/${dealer.usr_profile}`)}
/>
                  </td>
                  <td className="dealerdtls-requests__tbody-cell">{dealer.usr_name}</td>
                  <td className="dealerdtls-requests__tbody-cell">{dealer.usr_num}</td>
                  <td className="dealerdtls-requests__tbody-cell">{dealer.address}</td>
                  <td className="dealerdtls-requests__tbody-cell">{dealer.pincode}</td>
                  <td className="dealerdtls-requests__tbody-cell">{dealer.auth_sts}</td>
                  <td className="dealerdtls-requests__tbody-cell">

                        <img
                          src={dealer.id_proof && dealer.id_proof.trim() !== "" ? `${BASE_URL}/${dealer.id_proof}` : require('../assets/images/no_img.jpg')}
                          alt={`${dealer.usr_name} Document`}
                          className="dealerdtls-requests__proof-image"
                          onClick={() => dealer.id_proof && dealer.id_proof.trim() !== "" && openImagePopup(`${BASE_URL}/${dealer.id_proof}`)}
                        />

                  </td>
                  <td className="dealerdtls-requests__tbody-cell">
                    <button className="dealerdtls-requests__action-btn view-btn" onClick={() => handleView(dealer)}>view</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination Controls */}

        {currentDealers.length > 10 && (      
          <div className="pagination">
            <button 
              onClick={() => handlePageChange(currentPage - 1)} 
              disabled={currentPage === 1}
              className="pagination__button"
            >
              &laquo; Previous
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`pagination__button ${currentPage === index + 1 ? 'active' : ''}`}
              >
                {index + 1}
              </button>
            ))}
            <button 
              onClick={() => handlePageChange(currentPage + 1)} 
              disabled={currentPage === totalPages}
              className="pagination__button"
            >
              Next &raquo;
            </button>
          </div>
        )}

{currentDealers.length < 1 && (
   
    <center className='dlrno--data'><p>No dealers found...!</p></center>

)}
        
          {imagePopupOpen && (
            <ImagePopup 
              src={popupImageSrc} 
              alt="Vehicle" 
              onClose={closeImagePopup} 
            />
          )}

{/* Dealer Registration Form Popup */}
{dealerFormOpen && (
  <div className="dealer-form-overlay">
    <DealerForm 
      onClose={() => {
        setDealerFormOpen(false); 
        window.location.reload();
      }} 
    />
  </div>
)}

        </div>
      </div>
    </div>
  );
};

export default DealerDtls;
