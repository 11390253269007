import React from 'react';
import '../assets/css/footer.css';
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';
import main_logo from "../assets/logo/logo.png";

const Footer = () => {
  return (
    <footer className="footer">
<div className='footer_hed_main_div'>
        <div className='footer_main_div'>
            <div className='footer_main_div_1'>
            <img className='footlogo' src={main_logo} alt="Logo" />
                {/* <div className='line_div'></div> */}
                <p>At Sea Taxi,We are passionate about making your journey memorable and stress-free. Whether you're planning a local getway or a long-distance advanture,our reliable, comfort and experienced team ensure smooth,comfortable trteavel every</p>
                <div className='footer_social_icons'>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <FaFacebookF />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
        </div>
            </div>
        </div>


        <div className='footer_app_div'>
            <div className='footer_app_div_1'>
                <h2>Quick Link</h2>
                <div className='line_div'></div>
                <p>➔ Home</p>
                <p>➔ Near by your location</p>
                <p>➔ How it work</p>
                <p>➔ Why choose us</p>
                <p>➔ Our GlideFleets</p>
                <p>➔ We do more then you wish</p>
                <p>➔ Services</p>
               
            </div>
        </div>


        <div className='footer_app_div'>
            <div className='footer_app_div_1'>
                <h2>Download</h2>
                <div className='line_div'></div>
                <p>Download now and experience hassele-free Sea texi app at your fingertips!</p>
                <p>Android Play store</p>
                <p>Apple app store</p>
            </div>
        </div>



        <div className='footer_address_div'>
            <div className='footer_address_div_1'>
                <h2>Contact Details</h2>
                <div className='line_div'></div>
                 <div className='footerlisttile'>
                 <i className="ri-phone-line contact-icon"></i>
                      <div>
                      <h4>Phone Number</h4>
                      <p>04651-275555</p>
                      </div>
                  </div>
                 <div className='footerlisttile'>
                 <i className="ri-mail-line contact-icon"></i>
                      <div>
                      <h4>Email Address</h4>
                      <p>seataxi@gmail.com</p>
                      </div>
                  </div>
                 <div className='footerlisttile'>
                 <i className="ri-map-pin-line contact-icon"></i>
                      <div>
                      <h4>Office Location</h4>
                      <p>Near New Bus Stand, Marthandam<br />
                      629165</p>
                      </div>
                  </div>
            </div>
        </div>
            
</div>
    </footer>
  );
};

export default Footer;
