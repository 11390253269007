const navLinks = [
  {
    path: "/layout/dashboard",
    icon: "ri-home-line", // Home icon
    display: "Home",
  },
  {
    path: "/layout/profile",
    icon: "ri-user-line", // Profile icon
    display: "Profile / Contact",
  },
  {
    path: "/layout/addveh_type_cate",
    icon: "ri-add-box-line", // Profile icon
    display: "Add Type / Category",
  },
  {
    path: "/dealer-requests",
    icon: "ri-building-line", // Client Details icon
    display: "Dealer Request",
  },
  {
    path: "/vehicle-requests",
    icon: "ri-list-check", // Client Details icon
    display: "Vehicle Request",
  },
  {
    path: "/update-requests",
    icon: "ri-pencil-line", // Client Details icon
    display: "Update Request",
  },
  {
    path: "/layout/allorderdtls",
    icon: "ri-file-list-3-line", // All Orders icon
    display: "All Booking History",
  },
  {
    path: "/layout/allVehdetails",
    icon: "ri-car-line", // All Orders icon
    display: "All Vehicle Details",
  },
  {

    path: "/layout/bookings",
    icon: "ri-file-list-2-line", // Request Orders icon
    display: "Own Orders Details",
  },
  // {
  //   path: "/layout/acceptorder",
  //   icon: "ri-check-line", // Accept Orders icon
  //   display: "Accept Orders",
  // },
  // {
  //   path: "/layout/finishorder",
  //   icon: "ri-check-double-line", // Finished Orders icon
  //   display: "Finished Orders",
  // },
  // {
  //   path: "/layout/canceledorder",
  //   icon: "ri-close-line", // Canceled Orders icon
  //   display: "Canceled Orders",
  // },
  {
    path: "/layout/dealerdtls",
    icon: "ri-store-line", // Dealer Details icon
    display: "Dealer Details",
  },
  {
    path: "/layout/clientdtls",
    icon: "ri-user-3-line", // Client Details icon
    display: "Client Details",
  },

  // {
  //   path: "/layout/dealer-requests",
  //   icon: "", // Client Details icon
  //   display: "",
  // },


];

export default navLinks;
