import React, { useState, useEffect } from 'react';
import Sidebar from "../components/Sidebar/Sidebarr";
import TopNav from "../components/TopNav/TopNav";
import { BASE_URL } from '../api/config'; // Assuming your base API URL is here
import ConfirmationModal from "../components/Model/ConfirmationModal"; // Import your modal component
import "../styles/dealerrequest.css";
import ImagePopup from '../components/reuseable/ImagePopup';
import Swal from 'sweetalert2'; 

const DealerRequest = () => {
  const [dealerRequests, setDealerRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [selectedDealerId, setSelectedDealerId] = useState(null);
  const [imagePopupOpen, setImagePopupOpen] = useState(false);
  const [popupImageSrc, setPopupImageSrc] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [itemsPerPage, setItemsPerPage] = useState(10); // Number of items per page

  const openImagePopup = (src) => {
    setPopupImageSrc(src);
    setImagePopupOpen(true);
  };

  const closeImagePopup = () => {
    setImagePopupOpen(false);
  };

  useEffect(() => {
    const fetchDealerRequests = async () => {
      try {
        const accessToken = sessionStorage.getItem("accessToken"); // Fetch the token from sessionStorage
        const response = await fetch(`${BASE_URL}/api/getRegDealers`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`, // Ensure your request is authenticated
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch dealer requests');
        }

        const data = await response.json();
        setDealerRequests(data); // Set fetched data to the state
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDealerRequests();
  }, []);

  const openModal = (type, dealerId) => {
    setActionType(type);
    setSelectedDealerId(dealerId);
    setModalOpen(true);
  };

  const handleConfirm = async () => {
    if (actionType === 'accept') {
      await handleAccept(selectedDealerId);
    } else if (actionType === 'reject') {
      await handleReject(selectedDealerId);
    } else if (actionType === 'delete') {
      await handleDelete(selectedDealerId);
    }
    setModalOpen(false);
  };

  // Function to handle Accept action
  const handleAccept = async (dealer) => {
    try {
      const dealerId = dealer.id;
      const accessToken = sessionStorage.getItem("accessToken");
      const response = await fetch(`${BASE_URL}/api/ADMIN_Approve_Dealer`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            id: dealer.id.toString(),
            numbr: dealer.usr_num.toString(),
            verify: "1",
            Del_id: dealer.id.toString(),
        }),
      });

      if (response.ok) {
        setDealerRequests(dealerRequests.filter(dealer => dealer.id !== dealerId)); 
        Swal.fire({
          title: 'Dealer accepted!',
          // text: 'Invalid OTP',
          icon: 'success',
          timer: 2000,
              showConfirmButton: false,  // Hides the OK button
              customClass: {
                popup: 'custom-popup',    // Class for the alert popup
                title: 'custom-title',    // Class for the title
                content: 'custom-content' // Class for the content text
              }
        });
      } else {
        alert("Failed to accept dealer");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Function to handle Reject action
  const handleReject = async (dealer) => {
    try {
      const dealerId = dealer.id;
      const accessToken = sessionStorage.getItem("accessToken");
      const response = await fetch(`${BASE_URL}/api/ADMIN_Approve_Dealer`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            id: dealer.id.toString(),
            numbr: dealer.usr_num.toString(),
            verify: "2",
            Del_id: dealer.id.toString(),
        }),
      });

      if (response.ok) {
        setDealerRequests(dealerRequests.filter(dealer => dealer.id !== dealerId)); 
        Swal.fire({
          title: 'Dealer rejected!',
          // text: 'Invalid OTP',
          icon: 'success',
          timer: 2000,
              showConfirmButton: false,  // Hides the OK button
              customClass: {
                popup: 'custom-popup',    // Class for the alert popup
                title: 'custom-title',    // Class for the title
                content: 'custom-content' // Class for the content text
              }
        });
      } else {
        alert("Failed to reject dealer");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Function to handle Delete action
  const handleDelete = async (dealerId) => {
    try {
      const accessToken = sessionStorage.getItem("accessToken");
      const response = await fetch(`${BASE_URL}/api/deleteDealer/${dealerId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      });

      if (response.ok) {
        alert("Dealer deleted!");
        setDealerRequests(dealerRequests.filter((dealer) => dealer.id !== dealerId)); // Remove the deleted dealer from the list
      } else {
        alert("Failed to delete dealer");
      }
    } catch (error) {
      console.error(error);
    }
  };


  const indexOfLastDealer = currentPage * itemsPerPage;
  const indexOfFirstDealer = indexOfLastDealer - itemsPerPage;
  const currentDealersReq = dealerRequests.slice(indexOfFirstDealer, indexOfLastDealer);
  const totalPages = Math.ceil(dealerRequests.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  if (loading) return <center><div className="waitingspinner"></div></center>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="layout">
      <Sidebar />
      <div className="main__layout">
        <TopNav />
        <div className="dashboard">
          <div className="dashboard__wrapper">
            <div className="dealer-requests">
              <h2 className="dealer-requests__heading">Dealer Requests</h2>
              <table className="dealer-requests__table">
                <thead className="dealer-requests__thead">
                  <tr className="dealer-requests__thead-row">
                    <th className="dealer-requests__thead-cell">ID</th>
                    <th className="dealer-requests__thead-cell">Profile</th>
                    <th className="dealer-requests__thead-cell">Dealer Name</th>
                    <th className="dealer-requests__thead-cell">Phone Number</th>
                    <th className="dealer-requests__thead-cell">Address</th>
                    <th className="dealer-requests__thead-cell">Pincode</th>
                    <th className="dealer-requests__thead-cell">Auth Status</th>
                    <th className="dealer-requests__thead-cell">Id proof</th>
                    <th className="dealer-requests__thead-cell">Actions</th>
                  </tr>
                </thead>
                <tbody className="dealer-requests__tbody">
                  {currentDealersReq.map((dealer ,index) => (
                    <tr key={dealer.id} className="dealer-requests__tbody-row">
                      <td className="dealer-requests__tbody-cell">{indexOfFirstDealer+index + 1}</td>
                      <td className="dealer-requests__tbody-cell">
                      <img
  src={dealer.usr_profile ? `${BASE_URL}/${dealer.usr_profile}` : require('../assets/images/profile-02.png')}
  alt={`${dealer.usr_name} Profile`}
  className="dealer-requests__profile-image"
  onClick={() => dealer.usr_profile && openImagePopup(`${BASE_URL}/${dealer.usr_profile}`)}
/>

                      </td>
                      <td className="dealer-requests__tbody-cell">{dealer.usr_name}</td>
                      <td className="dealer-requests__tbody-cell">{dealer.usr_num}</td>
                      <td className="dealer-requests__tbody-cell">{dealer.address}</td>
                      <td className="dealer-requests__tbody-cell">{dealer.pincode}</td>
                      <td className="dealer-requests__tbody-cell">{dealer.auth_sts}</td>
                      <td className="dealer-requests__tbody-cell">
                        <img
                          src={dealer.id_proof && dealer.id_proof.trim() !== "" ? `${BASE_URL}/${dealer.id_proof}` : require('../assets/images/no_img.jpg')}
                          alt={`${dealer.usr_name} Document`}
                          className="dealer-requests__proof-image"
                          onClick={() => dealer.id_proof && dealer.id_proof.trim() !== "" && openImagePopup(`${BASE_URL}/${dealer.id_proof}`)}
                        />
                      </td>
                      <td className="dealer-requests__tbody-cell">
                        <button className="dealer-requests__action-btn accept-btn" onClick={() => openModal('accept', dealer)}>Accept</button>
                        <button className="dealer-requests__action-btn reject-btn" onClick={() => openModal('reject', dealer)}>Reject</button>
                        {/* <button className="dealer-requests__action-btn delete-btn" onClick={() => openModal('delete', dealer)}>Delete</button> */}
                        {/* <i className="ri-delete-bin-fill  dlrbinicon" onClick={() => openModal('delete', dealer)}></i> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>


              
                    
              {dealerRequests.length > 0 ? (
                 <p></p>
                ) : (
                 <center className='dlrno--data'><p>No Dealer Request found...!</p></center>
                )}


                
         {/* Pagination Controls */}
{dealerRequests.length > 10 && (  
  <div className="pagination">
    <button 
      onClick={() => handlePageChange(currentPage - 1)} 
      disabled={currentPage === 1}
      className="pagination__button"
    >
      &laquo; Previous
    </button>
    {Array.from({ length: totalPages }, (_, index) => (
      <button
        key={index + 1}
        onClick={() => handlePageChange(index + 1)}
        className={`pagination__button ${currentPage === index + 1 ? 'active' : ''}`}
      >
        {index + 1}
      </button>
    ))}
    <button 
      onClick={() => handlePageChange(currentPage + 1)} 
      disabled={currentPage === totalPages}
      className="pagination__button"
    >
      Next &raquo;
    </button>
  </div>
)}


            </div>
          </div>
        </div>
      </div>

      {imagePopupOpen && (
  <ImagePopup 
    src={popupImageSrc} 
    alt="Vehicle" 
    onClose={closeImagePopup} 
  />
)}

      <ConfirmationModal 
        isOpen={isModalOpen} 
        onClose={() => setModalOpen(false)} 
        onConfirm={handleConfirm} 
        message={`Are you sure you want to ${actionType} this dealer?`} 
      />
    </div>
  );
};

export default DealerRequest;
