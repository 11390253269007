import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import profileImg from "../../assets/images/profile-02.png";
import dashlogoImg from "../../assets/images/carload2.gif";
import "./TopNav.css";
import { BASE_URL } from '../../api/config';


const TopNav = () => {
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const notificationRef = useRef(null);

  const navigate = useNavigate();



  useEffect(() => {
    const accessToken = sessionStorage.getItem("accessToken");
    const LogNumber = localStorage.getItem("Number");

    // console.log('accessToken',accessToken,'LogNumber',LogNumber);
    
    if (!accessToken || !LogNumber) {
      navigate('/login'); // Redirect to login if token or user details are missing
    }

    fetchNotifications();
  }, [navigate]);

  // Function to fetch notifications
  const fetchNotifications = async () => {
    const accessToken = sessionStorage.getItem("accessToken"); 
    const userDetailsString = sessionStorage.getItem("userDetails");
    const userDetails = JSON.parse(userDetailsString);
  
    try {
      const response = await fetch(`${BASE_URL}/api/getNotific`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ 
          who: userDetails.whoislogin,
          lgid: userDetails.id,
        }),
      });
  
      const data = await response.json();
      setNotifications(data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };
  
  // Mark all notifications as read
  const markAsRead = async (id) => {
    const accessToken = sessionStorage.getItem("accessToken");
  
    const updatedNotifications = notifications.map((notification) =>
      notification.id === id ? { ...notification, visible: 1 } : notification
    );
    setNotifications(updatedNotifications);
  
    try {
      await fetch(`${BASE_URL}/api/MarkasRead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ notific_id: id }),
      });
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };
  
  // Toggle notification popup and fetch notifications
  const handleNotificationClick = () => {
    setShowNotificationPopup((prev) => !prev);
    if (!showNotificationPopup) {
      fetchNotifications();
    }
  };

  // Close popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setShowNotificationPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notificationRef]);

  const hasUnreadNotifications = notifications.some(
    (notification) => notification.visible === 0
  );

  return (
    <div className="top__nav">
      <div className="top__nav-wrapper">
        <div className="search__box">
          {/* <input type="text" placeholder="search or type" />
          <span>
            <i className="ri-search-line"></i>
          </span> */}
          <div className="dashleft-logo">
            
              <img src={dashlogoImg} alt="Profile" />
         
          </div>
        </div>

        <div className="top__nav-right">
          {/* Notification icon */}
          <span className="notification" onClick={handleNotificationClick}>
            <i className="ri-notification-3-line"></i>
            {hasUnreadNotifications &&(  
            <span className="badge">
              {hasUnreadNotifications && (notifications.filter((n) => n.visible === 0).length )}
            </span>
            )}
          </span>

          {/* Notification Popup */}
          {showNotificationPopup && (
            <div className="notification__popup" ref={notificationRef}>
              <h4>Notifications</h4>
              <div className="notification__list">
                {notifications.length > 0 ? (
                  notifications.map((notification, index) => (
                    <div
                      key={index}
                      className={`notification__item ${notification.visible === 0 ? "unread" : "read"}`}
                    >
                      <p className="msg">{notification.msg}</p>
                     <div className="msgbottom">
                     <small>{new Date(notification.time).toLocaleString()}</small>
                      {notification.visible === 0 && (
                        <button
                          className="mark-as-read-btn"
                          onClick={() => markAsRead(notification.id)}
                        >
                          Mark as read
                        </button>
                      )}
                     </div>
                    </div>
                  ))
                ) : (
                  <p>No any notifications</p>
                )}
              </div>
            </div>
          )}

          {/* Profile image */}
          <div className="profile">
            <Link to="/layout/profile">
              <img src={profileImg} alt="Profile" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
